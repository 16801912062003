import { getApiUrl } from '../environment.hci'

const ERR_PROMISE_REJECT_500 = "We weren't able to connect to the server. Please try again later.";
const HTTP_PATTERN = /^((http|https|ftp):\/\/)/;

export async function requireAuth(to, from, next) {
    if(!window.$auth0) {
        next();
    } else {
        let isAuthenticated = await window.$auth0.isAuthenticated();
        if(isAuthenticated) {
            next();
        } else {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });    
        }
    }
}

export function getIdToken() {
    return JSON.parse(localStorage.getItem('currentUser'));
}


export function isLoggedIn() {
    const idToken = getIdToken();
    return !!idToken;
}

export default {
    methods: {

        logOut: async function () {
            var path = '/login';

            try {
                await window.$auth0.logout({
                    logoutParams: {
                        returnTo: window.location.origin,
                        federated: true
                    }
                });
            } catch(auth0Ex) {
                console.error("Auth0 error on logOut - ", auth0Ex);
            }

            localStorage.clear();
            this.$router.push(path);
            this.$emit("auth:changed", false);


        },

        tryGetBinary: async function (path) {
            let auth0Token = await window.$auth0.getTokenSilently();
            var headers = { 
                'Authorization': `Bearer ${auth0Token}`
            };
            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'get',
                    credentials: 'include',
                    headers: headers
                });

                if (!r.ok) {
                    if (r.status === 401) {

                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                this.$router.push('/login');
                                return null;
                            }
                            return await this.tryGetBinary(path);
                        }
                        catch (ex) {
                            this.$router.push('/login');
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.blob();
                }
                catch (ex) {
                    return null;
                }

                return r;

            }
            catch (err) {
                return null;
            }
        },

        tryGetText: async function (path) {
            let auth0Token = await window.$auth0.getTokenSilently();
            var headers = {
                'Authorization': `Bearer ${auth0Token}`
            };

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'get',
                    credentials: 'include',
                    headers: headers
                });

                if (!r.ok) {
                    if (r.status === 401) {

                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                this.$router.push('/login');
                                return null;
                            }
                            return await this.tryGetText(path);
                        }
                        catch (ex) {
                            this.$router.push('/login');
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.text();
                }
                catch (ex) {
                    return null;
                }

                return r;

            }
            catch (err) {
                return null;
            }
        },

        async tryGet(path) {
            let auth0Token = await window.$auth0.getTokenSilently();
            var headers = {
                
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth0Token}`
            };

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'get',
                    credentials: 'include',
                    headers: headers
                });

                if (!r.ok) {
                    if (r.status === 401) {

                        try {
                            let result = await this.tryRefreshToken();
                            if(!result) {
                                this.$router.push('/login');
                                return null;
                            } 

                            return await this.tryGet(path);
                        } catch(ex) {
                            this.$router.push('/login');
                            return null;
                        }

                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }
                return r;

            }
            catch (err) {
                return {
                    Errors: [ERR_PROMISE_REJECT_500],
                    ErrorCodes: ['ERR_500'],
                    Result: null
                };
            }
        },

        async tryDelete(path) {

            let auth0Token = await window.$auth0.getTokenSilently();
            var headers = {
                
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth0Token}`
            };

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path,
                    {
                        mode: 'cors',
                        method: 'delete',
                        credentials: 'include',
                        headers: headers,
                    });

                if (!r.ok) {
                    if (r.status === 401) {
                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                this.$router.push('/login');
                                return null;
                            }
                            return await this.tryDelete(path);
                        }
                        catch (ex) {
                            this.$router.push('/login');
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                return r;
            }
            catch (ex) {
                return {
                    Errors: [r.statusText],
                    ErrorCodes: ['ERR_' + r.status],
                    Result: null
                };
            }
        },

        async tryPut(path) {

            let auth0Token = await window.$auth0.getTokenSilently();
            var headers = {
                
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth0Token}`
            };

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path,
                    {
                        mode: 'cors',
                        method: 'put',
                        credentials: 'include',
                        headers: headers,
                    });

                if (!r.ok) {
                    if (r.status === 401) {
                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                this.$router.push('/login');
                                return null;
                            }
                            return await this.tryDelete(path);
                        }
                        catch (ex) {
                            this.$router.push('/login');
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                return r;
            }
            catch (ex) {
                return {
                    Errors: [r.statusText],
                    ErrorCodes: ['ERR_' + r.status],
                    Result: null
                };
            }
        },

        async tryRefreshToken() {
            // TODO: Replace this with Auth0
            let isAuthenticated = await window.$auth0.isAuthenticated();

            if(!isAuthenticated) {
                return false;
            }

            return await window.$auth0.getTokenSilently();
        },

        async tryPost(path, data, contentType, redirectToLoginOnFail = true) {

            let auth0Token = await window.$auth0.getTokenSilently();
            var headers = {
                
                'Accept': 'application/json',
                'Authorization': `Bearer ${auth0Token}`
            };

            if (contentType) {
                headers['Content-Type'] = contentType;
            }

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path,
                    {
                        mode: 'cors',
                        method: 'post',
                        headers: headers,
                        body: data
                    });

                if (!r.ok) {
                    if (r.status === 401) {

                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                this.$router.push('/login');
                                return null;
                            }
                            return await this.tryPost(path, data, contentType, redirectToLoginOnFail);
                        }
                        catch (ex) {
                            this.$router.push('/login');
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                    return r;
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }
            }
            catch (ex) {
                return {
                    Errors: ["Network Error, please check your network connection and try again."],
                    ErrorCodes: ['ERR_Network'],
                    Result: null
                };
            }
        },

        async tryGetAnonymous(path) {

            var original = this;

            var headers = {
                'Accept': 'application/json',
            };

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'get',
                    credentials: 'include',
                    headers: headers
                });

                if (!r.ok) {
                    if (r.status === 401) {
                        try {
                            return null;
                        }
                        catch (ex) {
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                return r;
            }
            catch (err) {
                return {
                    Errors: ["Network Error, please check your network connection and try again."],
                    ErrorCodes: ['ERR_Network'],
                    Result: null
                };
            }
        },        
    }
}
