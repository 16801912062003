<template>
    <loading v-if="submitting">
    </loading>

    <div v-else>
        <nav-bar>

        </nav-bar>
        <vue-title :title="'Show List'"></vue-title>
        <div class="container-fluid">
            <div class="row mt-2">
                <div class="col">
                    <input class="form-control"
                           type="search"
                           v-model="searchFilter"
                           placeholder="Search...">
                </div>
                <div class="col-auto">
                    <router-link :to="{ name: 'ShowWizard'}"
                                 class="btn btn-primary">
                        New...
                    </router-link>                    
                </div>
            </div>

            <div class="row">

                <div class="col">
                    <table class="table table-responsive table-hover table-striped">
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="3" class="fw-bold fs-5">
                                    Communities
                                </td>
                            </tr>
                            <router-link class="pe-action" v-for="show in filteredEternalShows" tag="tr" :to="{ name: 'ShowEdit', params: { showCode: show.Code }}">
                                
                                <td class="font-monospace">
                                    {{ show.Code }}
                                </td>
                                <td colspan="2">
                                    {{ show.Name }}
                                </td>
                            </router-link>                            
                            <tr>
                                <td colspan="3" class="fw-bold fs-5">
                                    Shows
                                </td>
                            </tr>
                            <router-link class="pe-action" v-for="show in filteredShows" tag="tr" :to="{ name: 'ShowEdit', params: { showCode: show.Code }}">
                                
                                <td class="font-monospace">
                                    {{ show.Code }}
                                </td>
                                <td>
                                    {{ show.Name }}
                                </td>
                                <td>
                                    {{ show.StartDateTime | moment('ddd MMM Do YYYY')  }}
                                </td>
                            </router-link>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>

</style>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'

export default {
    mixins: [
        Token,
        Common
    ],

    computed: {

        filteredEternalShows() {

            let result = this.shows;

            result = result.filter(x => x.EternalShow);

            if(this.searchFilter) {
                let searchTest = new RegExp(this.searchFilter, 'i');
                result = result.filter(x => searchTest.test(x.Code) || searchTest.test(x.Name));
            }

            return result;
        },


        filteredShows() {
            let result = this.shows;

            result = result.filter(x => !x.EternalShow);

            if(this.searchFilter) {
                let searchTest = new RegExp(this.searchFilter, 'i');
                result = result.filter(x => searchTest.test(x.Code) || searchTest.test(x.Name));
            }

            return result;
        }
    },

    data() {
        return {
            shows: [],
            searchFilter: '',
            filterMode: 'shows',
            filterModes: [ 
                {
                    Code: 'all',
                    Name: 'View All'
                },
                {
                    Code: 'eternal',
                    Name: 'Communities'
                },
                {
                    Code: 'shows',
                    Name: 'Shows'
                }
            ]
        }
    },

    methods: {
        async reloadShowList() {
            this.submitting = true;
            try{
                let r = await this.tryGet('/api/shows?mode=list');
                this.shows = r.Result;
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;

            let token = await window.$auth0.getTokenSilently();
            await window.$signalr.initializeAsync(token);
        },
    },

    created: function() {
        this.reloadShowList();
    }
}
</script>

