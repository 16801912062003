<template>
    <div class="container h-100 d-flex justify-content-center align-items-center">
        <vue-title :title="'Login'"></vue-title>
        <div class="card mx-auto mt-1"
             style="max-width: 350px; min-width: 350px">

            <div class="card-body text-center">
                <form autocomplete="on" v-on:submit.prevent="onLogin">
                    <h5>Tractus Login</h5>

                    <div class="d-grid gap-2">

                        <button type="button" @click="onLoginAsync"
                                class="btn btn-success">
                            Sign In
                        </button>

                        <div v-if="validateErrors && validateErrors.length > 0" class="alert alert-danger text-start" role="alert">
                            <h4 class="alert-heading">Could not log in</h4>
                            <hr>
                            <p v-for="error in validateErrors">
                                {{error}}
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Token from './authentication/token'


export default {
  mixins: [
      Token
  ],
  name: 'PublicPollResponse',
  data () {
    return {
      validateErrors: [],
      submitting: false,
      userName: '',
      password: ''
    }
  },

  computed: {
  },

  created: async function() {
      this.userName = localStorage.getItem("userName");
      window.$bus.$on('auth:changed', this.onAuth0Ready);
      this.onAuth0Ready();
  },

  methods: {
    async onAuth0Ready() {
        let isAuthenticated = await window.$auth0.isAuthenticated();

        if(isAuthenticated) {
            this.$router.push('/shows');
        }
    },

    async onLoginAsync() {
        let auth0Client = window.$auth0

        const isAuthenticated = await auth0Client.isAuthenticated();
        let user1 = await auth0Client.getUser();
        console.log(user1);

        console.log(isAuthenticated);
        if(isAuthenticated || user1) {
            this.$router.push('/shows');
            console.log("Auth'd!");
            return;
        }


        console.log(window.location.origin)
        await auth0Client.loginWithRedirect({
            authorizationParams: {
                redirect_uri: window.location.origin,
            },
        })

        let user = await auth0Client.getUser();
        console.log(user);
        let authStatus = await auth0Client.isAuthenticated();
        console.log(authStatus);

        let token = await auth0Client.getTokenSilently({
            audience: 'http://api.tractus.ca'
        });

        console.log("Token: ", token);
    },

    validateForm: function() {
        this.validateErrors = [];
        
        if(!this.userName) {
            this.validateErrors.push("User name cannot be blank.");
        }

        if(!this.password) {
            this.validateErrors.push("Password cannot be blank.");
        }

        return this.validateErrors.length === 0;
    },

    async onLogin(ev) {
      this.submitting = true;

      
      this.submitting = false;
    },
  }
}
</script>
<style scoped>
.form-signin {
  width: 100%;
  max-width: 450px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
