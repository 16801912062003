<template>
    <div id="app">
        <router-view/>

        <div class="test-env-badge-container">
            <div v-if="environmentIsDevTest && forceShowTestBadge"
                    class="test-env-badge"
                    @click="displayTestEnvironmentMessage">
                {{environmentBadge}}
            </div>
        </div>
  </div>
</template>
<style>
.test-env-badge-container {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    height: 160px;
    width: 160px;
    overflow: hidden;
    pointer-events: none;
}

.test-env-badge {
    pointer-events: all;
    position: relative;
    top: 30px;
    right: 20px;
    z-index: 9999;
    transform: rotate(45deg);
    background: #991100;
    width: 250px;
    height: 32px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    text-align: center;
    cursor: pointer;
    border: 4px solid #ee2200;
    box-shadow: 2px 2px 2px #bbb;
    text-transform: uppercase;
    overflow-y: hidden !important;
}
</style>
<script>
export default {
    data() {
        return {
            forceShowTestBadge: false,
            testBadgeClicks: 0,
        }
    },

    computed: {
        environmentBadge() {

            if(window.location.hostname.indexOf('localhost') != -1) {
                return "Local";
            }

            return 'Testing';
        },

        environmentIsDevTest() {
            return window.location.hostname.indexOf('localhost') != -1
                || window.location.hostname.indexOf('apptest.tractus') != -1;
        },
       
    },

    methods: {
        displayTestEnvironmentMessage() {
            this.testBadgeClicks++;

            if(this.testBadgeClicks == 5) {
                this.forceShowTestBadge = false;
            }
        },

    },

    created() {
        if(this.environmentIsDevTest) {
            this.forceShowTestBadge = true;
        }        
    }
}
</script>