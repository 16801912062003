<template>
    <div v-if="submitting">
        <loading></loading>
    </div>
    <div v-else
         class="container-fluid scrolling-columns">
        <div class="row">
            <div class="col">
                <div class="row pt-2 pb-2 border-bottom sticky-top bg-dark">
                    <div class="col">
                        <span class="flex-fill fs-5 ps-1">
                            Streams
                        </span>
                    </div>
                    <div class="col-auto">
                        <div class="btn-group">
                            <button class="btn btn-outline-primary"
                                    @click="createRow">
                                New...
                            </button>
                            <button class="btn btn-outline-primary"
                                    @click="build">
                                Refresh
                            </button>
                        </div>

                    </div>
                </div>

                <div class="row"
                     v-for="stream in streams"
                     :key="stream.LiveStreamId">
                    <div class="col">
                        <div class="border m-2 p-3 mt-3 w-100">

                            <div class="input-group">
                                <input v-model="stream.Name"
                                    class="form-control">
                                <button @click="deleteStream(stream)"
                                    class="btn btn-danger btn-sm">
                                    Delete
                                </button>
                                <button @click="finishStream(stream)"
                                    class="btn btn-warning btn-sm">
                                    Complete
                                </button>
                                <button @click="saveChanges(stream)"
                                    class="btn btn-success btn-sm">
                                    Save
                                </button>
                            </div>



                            <div class="input-group input-group-sm mt-2">
                                <span class="input-group-text">
                                    Slate URL
                                </span>
                                <input class="form-control form-control-sm"
                                    v-model="stream.ReconnectSlateUrl">
                            </div>




                            <div class="input-group input-group-sm mt-2" v-if="showDetails.IsPublicShow && stream.NextAssociatedSessionCode">
                                <span class="input-group-text">
                                    Watch URL
                                </span>
                                <input class="form-control form-control-sm"
                                       :value="`${webinarPageUrl}/watch/${showDetails.Code}/${stream.NextAssociatedSessionCode.split('_')[1]}`"
                                       readonly
                                       @click="copyWatchUrl(stream)">
                            </div>                      
                            
                            <div class="input-group input-group-sm mt-2">
                                <span class="input-group-text">
                                    Auto-Captions
                                </span>
                                <select class="form-select form-select-sm"
                                        v-model="stream.SubtitleLanguageCode">
                                    <option :value="''">
                                        (No Subtitles)
                                    </option>    
                                    <option v-for="language in languageCodesAuto"
                                            :value="language.code">
                                        {{ language.name }}    
                                    </option>
                                </select>
                                <span class="input-group-text">
                                    Manual Captions
                                </span>
                                <select class="form-select form-select-sm"
                                        v-model="stream.SubtitleLanguageCodeManual">
                                    <option :value="''">
                                        (No Subtitles)
                                    </option>    
                                    <option v-for="language in languageCodes"
                                            :value="language.code">
                                        {{ language.name }}    
                                    </option>
                                </select>                                
                            </div>                            

                            <template v-if="stream.LiveStreamId">
                                <div class="row">
                                    <div class="col">
                                        <table class="table mt-3">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        Status
                                                    </th>
                                                    <td>
                                                        <span v-if="stream.LiveStreamId" class="badge"
                                                            :class="{'text-success bg-success-subtle': stream.Status == 'idle',
                                                                'text-danger bg-danger text-white fw-bold': stream.Status == 'active' || stream.Status == 'recording' || stream.Status == 'connected',
                                                                'bg-warning-subtle text-warning-emphasis': stream.Status == 'disconnected'}">
                                                            {{ stream.Status }} <span v-if="stream.Status == 'active'">({{ getLiveDuration(stream.ActiveDateTimeUtc, currentTime) }})</span>
                                                        </span>
                                                        <span class="badge" v-if="stream.Status == 'active'">{{ stream.ActiveDateTimeUtc | moment('L HH:mm:ss') }}</span>
                                                    </td>
                                                    <th>
                                                        Encoder Status
                                                    </th>
                                                    <td>
                                                        <span v-if="stream.LiveStatus" class="badge"
                                                            :class="{'text-success bg-success-subtle': stream.LiveStatus == 'idle',
                                                                'text-danger bg-danger text-white fw-bold': stream.LiveStatus == 'connected' || stream.LiveStatus == 'active' || stream.LiveStatus == 'recording' || stream.LiveStatus == 'connected',
                                                                'bg-warning-subtle text-warning-emphasis': stream.LiveStatus == 'disconnected'}">
                                                            {{ stream.LiveStatus }} 
                                                        </span>
                                                        <span class="badge" v-if="stream.LiveStatus == 'connected' || stream.LiveStatus == 'recording' || stream.LiveStatus == 'active'">{{ stream.RecordingStartDateTimeUtc | moment('L HH:mm:ss') }}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="4">
                                                        <div class="input-group input-group-sm">
                                                            <span class="input-group-text">
                                                                Session
                                                            </span>
                                                            <select class="form-select form-select-sm"
                                                                v-model="stream.NextAssociatedSessionCode">
                                                                <option :value="showCode">
                                                                    (No Session Selected)
                                                                </option>
                                                                <optgroup v-for="(category, key) in sessions"
                                                                        :key="key"
                                                                        :label="getSessionCategoryName(key)">
                                                                    <option v-for="session in category"
                                                                            :key="session.Code"
                                                                            :value="`${showCode}_${session.Code}`">
                                                                        {{ session.Name }}
                                                                    </option>
                                                                </optgroup>
                                                            </select>
                                                            <button class="btn btn-outline-danger"
                                                                    @click="clearAssignment(stream)">
                                                                Clear
                                                            </button>
                                                            <button class="btn btn-outline-primary"
                                                                    @click="assignToRoom(stream)">
                                                                Assign
                                                            </button>
                                                            <button class="btn btn-danger"
                                                                    @click="makeAssignmentLive(stream)">
                                                                TAKE
                                                            </button>
                                                            <button class="btn btn-danger"
                                                                    :disabled="!stream.DvrPlaybackId"
                                                                    @click="makeAssignmentLiveDvr(stream)">
                                                                DVR
                                                            </button>
                                                        </div>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="input-group input-group-sm mt-2">
                                            <span class="input-group-text">
                                                RTMP URL
                                            </span>
                                            <input class="form-control form-control-sm"
                                                readonly
                                                value="rtmps://global-live.mux.com:443/app"
                                                @click="copyRtmpUrlToClipboard(stream)">
                                                <span class="input-group-text">
                                                SRT URL
                                            </span>
                                            <input class="form-control form-control-sm"
                                                readonly
                                                value="srt://global-live.mux.com:6001"
                                                @click="copySrtUrlToClipboard(stream)">
                                        </div>
                                        <div class="input-group input-group-sm mt-2">
                                            <span class="input-group-text">
                                                RTMP Key/SRT ID
                                            </span>
                                            <input class="form-control form-control-sm blur-input"
                                                readonly
                                                :value="stream.StreamKey"
                                                @click="copyStreamKeyToClipboard(stream)">
                                            <span class="input-group-text">
                                                SRT Passphrase
                                            </span>
                                            <input class="form-control form-control-sm blur-input"
                                                readonly
                                                :value="stream.SrtPassPhrase"
                                                @click="copySrtPassphraseToClipboard(stream)">

                                        </div>
                                        <div class="input-group input-group-sm mt-2">
                                            <span class="input-group-text">
                                                Playback Key
                                            </span>
                                            <input class="form-control form-control-sm blur-input"
                                                readonly
                                                :value="stream.PlaybackKey">
                                            <span class="input-group-text">
                                                Mux Live Stream ID
                                            </span>
                                            <input class="form-control form-control-sm"
                                                readonly
                                                :value="stream.LiveStreamId">
                                        </div>

                                        <div class="input-group input-group-sm mt-2">
                                            <span class="input-group-text">
                                                URL to Launch
                                            </span>
                                            <input class="form-control form-control-sm"
                                                v-model="stream.PostStreamUrl">
                                            
                                            <button @click="onLaunchPostStreamUrl(stream)"
                                                    type="button"
                                                    class="btn btn-primary">
                                                <i class="bi bi-arrow-bar-up"></i>
                                            </button>
                                            <button @click="copyPopupUrlToClipboard(stream)"
                                                    type="button"
                                                    class="btn btn-primary">
                                                <i class="bi bi-share"></i>
                                            </button>
                                            <button @click="onDismissPostStreamUrl(stream)"
                                                    type="button"
                                                    class="btn btn-primary">
                                                <i class="bi bi-x-circle-fill"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col" v-if="stream.PlaybackKey && stream.Status == 'active'">
                                        <div class="border m-1 p-2 mt-3">
                                            <h2>{{ stream.Name }} - Preview</h2>
                                            <mux-player
                                                :playback-id="stream.PlaybackKey"
                                                :metadata-video-id="`${showCode}_${stream.LiveStreamId}`"
                                                :metadata-video-title="stream.Name"
                                                metadata-viewer-user-id="AdminConsole"
                                            />                                

                                        </div>
                                    </div>
                                </div>

                            </template>
                        </div>

                    </div>

                </div>
            </div>
            <!--<div class="col-auto session-map-list">
                <div class=" row pt-2 pb-2 border-bottom sticky-top bg-dark">
                    <div class="col">
                        <span class="flex-fill fs-5 ps-1">
                            Session Mappings
                        </span>
                    </div>
                    <div class="col-auto">
                        <div class="btn-group">
                            <button class="btn btn-outline-primary"
                                    @click="build">
                                Refresh
                            </button>
                        </div>
                    </div>                    
                </div>


                <table class="table table-responsive table-sm">
                    <tbody>
                        <template v-for="(category, key) in sessions">
                            <tr>
                                <th colspan="3">
                                    {{ getSessionCategoryName(key) }}
                                </th>
                            </tr>
                            <tr v-for="session in category" :key="session.Code">
                                <td>
                                    {{ session.Name }}
                                </td>
                                <td width="150">
                                    {{ session.SessionStartDateTime | moment('YY-MM-DD HH:mm') }}
                                </td>
                                <td>
                                    <div v-if="session.PrimaryMedia && session.PrimaryMedia.MediaType == 'MuxLive'"
                                         class="badge text-bg-success">
                                        Mux Live
                                    </div>
                                    <div v-else-if="session.PrimaryMedia && session.PrimaryMedia.MediaType == 'MuxAsset'"
                                         class="badge text-bg-primary">
                                        Replay
                                    </div>
                                    <div v-else-if="session.PrimaryMedia && session.PrimaryMedia.MediaType == 'Video' && session.PrimaryMedia.Uri"
                                         class="badge text-bg-warning">
                                        Video (M3U8)
                                    </div>
                                    <div v-else-if="session.PrimaryMedia && session.PrimaryMedia.MediaType == 'LiveWebSource' && session.PrimaryMedia.Uri"
                                        class="badge text-bg-warning">
                                        Embed
                                    </div>
                                    <div v-else
                                         class="badge text-bg-secondary">
                                        N/A
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>-->

        </div>
    </div>
</template>
<script>
import "@mux/mux-player";

import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import ExcelJS from 'exceljs'
import Toastify from 'toastify-js'
import { offset } from "@popperjs/core";

export default {
    props: ['showCode','showDetails'],

    mixins: [
        Token,
        Common
    ],
    
    computed: {
        webinarPageUrl() {
            return process.env.VUE_APP_WEBINAR_APP_URL;
        }
    },

    methods: {
        getLiveDuration(activeDateTimeUtc, currentTime) {
            if(!activeDateTimeUtc) {
                return;
            }

            try {
                let now = currentTime;
                let then = new Date(activeDateTimeUtc);
                
                let totalSeconds = Math.round((now - then) / 1000.0);

                let totalMinutes = Math.floor(totalSeconds / 60);
                let seconds = totalSeconds % 60.0;
                return `${totalMinutes}:${String(seconds).padStart(2, '0')}`;

            } catch {
                return 'ERR';
            }
        },

        copyRtmpUrlToClipboard(item) {
            navigator.clipboard.writeText(`rtmps://global-live.mux.com:443/app`)
            Toastify({
                text: "Copied the RTMP URL to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },

        copyStreamKeyToClipboard(item) {
            navigator.clipboard.writeText(item.StreamKey)
            Toastify({
                text: "Copied the RTMP stream key/SRT stream ID to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },

        copySrtPassphraseToClipboard(item) {
            navigator.clipboard.writeText(item.SrtPassPhrase)
            Toastify({
                text: "Copied SRT passphrase to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },

        copySrtUrlToClipboard(item){
            let srtUrl = `srt://global-live.mux.com:6001?streamid=${item.StreamKey}&passphrase=${item.SrtPassPhrase}`;
            navigator.clipboard.writeText(srtUrl)
            Toastify({
                text: "Copied the SRT ingest URL to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },
        
        getPopupUrl(stream) {
            let url = `/api/mux/livestream/${this.showCode}/${stream.LiveStreamId}/popup?url=${encodeURIComponent(stream.PostStreamUrl)}`;
            return url;
        },

        copyPopupUrlToClipboard(stream) {
            let url = this.getPopupUrl(stream);
            navigator.clipboard.writeText(`${process.env.VUE_APP_API_ENDPOINT}${url}`);
            Toastify({
                text: "Copied the popup URL to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },

        async onLaunchPostStreamUrl(stream) {
            let url = this.getPopupUrl(stream);
            await this.tryPut(url);

            Toastify({
                text: "Posted the URL to all attendees.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },

        async onDismissPostStreamUrl(stream) {
            let url = `/api/mux/livestream/${this.showCode}/${stream.LiveStreamId}/popup/dismiss`;
            await this.tryPut(url);

            Toastify({
                text: "Cleared the URL to all attendees.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();

        },

        copyWatchUrl(stream) {
            let url = `${this.webinarPageUrl}/watch/${this.showDetails.Code}/${stream.NextAssociatedSessionCode.split('_')[1]}`;
            navigator.clipboard.writeText(url);
            Toastify({
                text: "Copied webinar watch link to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },

        async makeAssignmentLive(stream) {
            this.submitting = true;
            let url = `/api/mux/livestream/${this.showCode}/${stream.LiveStreamId}/makelive`;
            var result = await this.tryPut(url);
            this.build();
        },

        async makeAssignmentLiveDvr(stream) {
            this.submitting = true;
            let url = `/api/mux/livestream/${this.showCode}/${stream.LiveStreamId}/makelive/dvr`;
            var result = await this.tryPut(url);
            this.build();
        },

        async assignToRoom(stream) {
            this.submitting = true;
            let url = `/api/mux/livestream/${this.showCode}/${stream.LiveStreamId}/assign/${stream.NextAssociatedSessionCode}`;
            var result = await this.tryPut(url);
            this.build();
        },

        async clearAssignment(stream) {
            // api/mux/livestream/{showCode}/{streamCode}/assign/{sessionCode?}
            this.submitting = true;
            let url = `/api/mux/livestream/${this.showCode}/${stream.LiveStreamId}/assign/${this.showCode}`;
            var result = await this.tryPut(url);
            this.build();
        },

        getSessionCategoryName(categoryCode) {
            let category = this.showDetails.SessionCategories.find(x => x.Code == categoryCode);
            if(!category) {
                return categoryCode;
            }

            return category.Name;
        },

        async build() {
            this.submitting = true;
            
            try {

                let url = `/api/mux/livestreams/${this.showCode}`;
                let result = await this.tryGet(url);

                this.streams = result.Result.sort((a, b) => a.Name.localeCompare(b.Name));

                result = await this.tryGet(`/api/show/${this.showCode}/sessions`);
                this.sessions = result.Result;

            } catch(ex) {
                console.error(ex);
            }

            this.submitting = false;
        },        

        createRow() {
            /*
                
                    public string ShowCode { get; set; }
    public string LiveStreamId { get; set; }
    public string Name { get; set; }
    public string StreamKey { get; set; }
    public string PlaybackKey { get; set; }
    public string NextAssociatedSessionCode { get; set; }
    public string Status { get; set; }

            */
            let toPush = {
                ShowCode: this.showCode,
                LiveStreamId: '',
                Name: 'New Stream Endpoint',
                StreamKey: '',
                PlaybackKey: '',
                NextAssociatedSessionCode: this.showCode,
                Status: 'new',
                ReconnectSlateUrl: '',
                SubtitleLanguageCode: '',
            };

            this.streams.splice(0, 0, toPush);
            this.selectedRow = toPush;
        },

        async saveChanges(stream) {
            this.submitting = true;

            let toSave = stream;

            let url = `/api/mux/livestream`;

            try {
                var result = await this.tryPost(
                    url,
                    JSON.stringify(toSave), 
                    'application/json');

                this.build();
            } catch(ex) {
                console.error(ex);
                Toastify({
                    text: "Server error. See console.error.",
                    duration: 2500,
                    gravity: "top",
                    position: "right",
                }).showToast();                    
                this.submitting = false;
            }

        },

        async deleteStream(stream) {
            if(!confirm("Are you absolutely sure you want to delete this stream?")) {
                return;
            }

            if(!stream.LiveStreamId) {
                this.build();
                return;
            }

            this.submitting = true;
            let url = `/api/mux/livestreams/${this.showCode}/${stream.LiveStreamId}`;
            var result = await this.tryDelete(url);    
            
            this.build();
        },

        onControlChannelMessage(data) {

            console.warn(data);
            if(!data.TypeCode ) {
                return;
            }

            if(data.TypeCode == 'EntityUpdated') {
                this.build();
                return;
            }

            if(data.TypeCode == 'MuxStreamUpdated') {
                if(data.ShowCode != this.showCode) {
                    console.warn("Mismatch of show code. Return");
                    return;
                }

                console.info("Got past the guards.");

                let stream = this.streams.find(x => x.LiveStreamId == data.Message.LiveStreamId);
                if(!stream) {
                    return;
                }

                

                stream.Status = data.Message.Status;
                stream.LiveStatus = data.Message.LiveStatus;
                stream.RecordingStartDateTimeUtc = data.Message.RecordingStartDateTimeUtc;
                stream.ActiveDateTimeUtc = data.Message.ActiveDateTimeUtc;
            }

            if(data.TypeCode == "MuxStreamAssetReady") {
                this.build();
                return;
            }

            if(data.TypeCode == 'MuxVideoUpdated') {
                if(data.ShowCode != this.showCode) {
                    console.warn("Mismatch of show code. Return");
                    return;
                }

                if(!data.Message.LiveStreamId) {
                    return;
                }

                let stream = this.streams.find(x => x.LiveStreamId == data.Message.LiveStreamId);
                if(!stream) {
                    return;
                }

                if(data.Message.EventType != 'video.asset.live_stream_completed') {
                    return;
                }

                Toastify({
                    text: `Stream '${stream.Name}' just got a new replay asset. You can unassign it now.`,
                    duration: 5000,
                    gravity: "bottom",
                    position: "right",
                }).showToast();
            }
        },

        async finishStream(stream) {
            this.submitting = true;
            let url = `/api/mux/livestreams/${this.showCode}/${stream.LiveStreamId}/complete`;
            var result = await this.tryPut(url);
            this.build();

        },
    },

    data(){ 
        return{        
            currentTime: new Date(),   
            streams: [],
            selectedRow: null,
            sessions: [],
            languageCodesAuto: [
                { code: "en", name: "English" },
                { code: "en-US", name: "English (US)" },
            ],
            languageCodes: [
                { code: "fr", name: "French" },
                { code: "en-US", name: "English (US)" },
                { code: "es", name: "Spanish" },
                { code: "it", name: "Italian" },
                { code: "pt", name: "Portuguese" },
                { code: "de", name: "German" },
                { code: "pl", name: "Polish" },
                { code: "ru", name: "Russian" },
                { code: "nl", name: "Dutch" },
                { code: "ca", name: "Catalan" },
                { code: "tr", name: "Turkish" },
                { code: "sv", name: "Swedish" },
                { code: "uk", name: "Ukrainian" },
                { code: "no", name: "Norwegian" },
                { code: "fi", name: "Finnish" },
                { code: "sk", name: "Slovak" },
                { code: "el", name: "Greek" },
                { code: "cs", name: "Czech" },
                { code: "hr", name: "Croatian" },
                { code: "da", name: "Danish" },
                { code: "ro", name: "Romanian" },
                { code: "bg", name: "Bulgarian" }
            ],
            timer: null,
        }
    },

    async created(){
        await this.build();
        this.timer = setInterval(() => {
            this.currentTime = new Date();
        }, 1000);
        this.bus.$on('Control-Channel-Message', this.onControlChannelMessage);
    },

    beforeDestroy() {
        this.bus.$off('Control-Channel-Message', this.onControlChannelMessage);
        clearInterval(this.timer);
    }
}
</script>