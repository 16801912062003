import Vue from 'vue'
import App from './App'
import router from './router'
import titleComponent from './components/title.component'
// import pollAnswerComponent from './components/PollAnswer'

// Navigation Bar
import Nav from './components/nav'

// List Item Renderers
import PollItem from './components/list-items/poll.item'
import SessionVendorItem from './components/list-items/session-vendor'
// Editors
import SessionCategoryEditor from './components/editors/sessioncategory.vue'
import AttendeeEditor from './components/editors/attendee'
import VendorEditor from './components/editors/vendor'
import PollEditor from './components/editors/poll'
import SessionEditor from './components/editors/session'
import PushNotificationEditor from './components/editors/push.notification'
import NotificationTemplateEditor from './components/editors/notification.template'
import NotificationEditor from './components/editors/notification'
import ContactInfoEditor from './components/editors/contact.info'
import AttendeeCategoryEditor from './components/editors/attendeecategory'
import DiscussionAreaEditor from './components/editors/discussion.area.vue'
import MediaInformation from './components/editors/media-information.vue'

import FileUpload from './components/fileUpload'
import FilePicker from './components/filePicker'

import Loading from './components/loading'

import PollEdit from './components/poll.edit'
import { Chrome } from 'vue-color'
import { VueEditor } from 'vue2-editor'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort';

import AssetListItem from './components/assetListItem';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import ResourceSelector from './components/editors/resource.selector';
import AttendeePicker from './components/editors/attendee.picker';

import AppThemeEditor from './components/app.theme.editor'

import BroadcasterListItem from './components/list-items/broadcaster.vue'
import AnnouncementItem from './components/list-items/announcement.item.vue'

//import ImageResize from 'quill-image-resize-module'
import { Quill } from 'vue2-editor'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/style.css';
import 'bootstrap-icons/font/bootstrap-icons.css'
import "toastify-js/src/toastify.css"

import { Tooltip, Alert, Modal } from 'bootstrap'
import vueDebounce from 'vue-debounce'
import VideoPlayer from './components/video.player.vue';
import { EventBus, EventBusLogLevels } from "@agfinn/yet-another-bus"

import { TractusSignalRController } from './components/signalr.controller';
import { createAuth0Client } from '@auth0/auth0-spa-js';


Vue.prototype.bus = new EventBus();
window.$bus = Vue.prototype.bus;

Vue.prototype.signalr = new TractusSignalRController(Vue.prototype.bus, process.env.VUE_APP_API_ENDPOINT);
window.$signalr = Vue.prototype.signalr;

console.log("WINDOW BUS:", window.$bus)

Vue.use(vueDebounce)

Vue.component('ctk-date-time-picker',VueCtkDateTimePicker);
Vue.component('resource-selector', ResourceSelector);
Vue.component('attendee-picker', AttendeePicker);

Vue.component('stream-broadcaster-item', BroadcasterListItem);
Vue.component('announcement-item', AnnouncementItem)

Vue.component('vue-editor',VueEditor)
Vue.component('chrome-picker',Chrome)
Vue.component('vue-slicklist',SlickList)
Vue.component('vue-slickitem',SlickItem)
Vue.component('asset-list-item',AssetListItem)
Vue.component('vue-title',titleComponent)
Vue.component('file-upload',FileUpload)
Vue.component('file-picker',FilePicker)

Vue.component('poll-item',PollItem)
Vue.component('session-vendor-item',SessionVendorItem)
Vue.component('nav-bar',Nav)
Vue.component('session-category-editor',SessionCategoryEditor)
Vue.component('session-editor',SessionEditor)
Vue.component('attendee-editor',AttendeeEditor)
Vue.component('attendee-category-editor',AttendeeCategoryEditor)

Vue.component('vendor-editor',VendorEditor)
Vue.component('media-information',MediaInformation)

Vue.component('push-notification-editor',PushNotificationEditor)

Vue.component('notification-template-editor',NotificationTemplateEditor)
Vue.component('notification-editor',NotificationEditor)
Vue.component('contact-info-editor',ContactInfoEditor)
Vue.component('poll-editor',PollEditor)
Vue.component('poll-view',PollEdit)

Vue.component('loading',Loading)
Vue.component('discussion-editor', DiscussionAreaEditor)
Vue.component('app-theme-editor', AppThemeEditor)
Vue.component('video-player', VideoPlayer)


import ShowBasicDetailsWizardPage from './components/show-wizard/show.basic.details';
Vue.component('show-basic-details-wizard-page', ShowBasicDetailsWizardPage)

Vue.config.productionTip = process.env.VUE_APP_DEBUG_MODE;
Vue.use(require('vue-moment'));

Vue.directive('handle', HandleDirective)

String.prototype.toTitle = function() {
  return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}

async function initializeApp() {
    window.$auth0 = await createAuth0Client({
        'domain': 'tractus.ca.auth0.com',
        'clientId': 'ULTXcOJg3F4rBhhnqCBBy8eBmNDcXuQp',
        useRefreshTokens: true,
        scope: 'openid profile email offline_access',
        cacheLocation: 'localstorage',
        authorizationParams: {
            audience: 'http://api.tractus.ca'
        }
    });

    window.$bus.$emit('auth0-ready');

    Vue.nextTick(async() => {
        new Vue({
            el: '#app',
            router,
            render: h => h(App)
        })
          
        let isAuthenticated = await window.$auth0.isAuthenticated();
        
        let queryParams = window.location.search || '';

        if(queryParams.indexOf('code=') != -1 && queryParams.indexOf("state=") != -1
            && window.$auth0) {
            try {
                await window.$auth0.handleRedirectCallback();
            } catch(ex) {

            }

            const { protocol, host, pathname, hash } = window.location;
            const newUrl = `${protocol}//${host}${pathname}${hash}`;
            window.history.replaceState({}, document.title, newUrl);
            console.log('NEW URU ----', newUrl)
        }


        window.$bus.$emit("auth:changed", isAuthenticated);
    });
}

/* eslint-disable no-new */
initializeApp();
